import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react"
import {Container} from "aurelia-dependency-injection"
import {AureliaConfiguration} from "aurelia-configuration"
import {FlashService} from "../flash/flash-service"
import {AuthTokenStorage} from "../auth/auth-token-storage"

/**
 * @typedef {{ id: string, modelId: string }} ObjectRef
 * @typedef {{ amount: int, currency: string }} Money
 */

/** @type FlashService */
const flash = Container.instance.get(FlashService)
const baseUrl = Container.instance.get(AureliaConfiguration).get('apiUrl')

const baseQuery = fetchBaseQuery({
    baseUrl,
    prepareHeaders(headers) {
        // @todo refresh token
        headers.set("authorization", "Bearer " + Container.instance.get(AuthTokenStorage).getAccessToken())
        return headers
    }
})

/** @type {(successMessage?: string) => (_: any, {queryFulfilled: Promise}) => Promise} */
export const onQueryStarted = successMessage => (_, {queryFulfilled}) => queryFulfilled.then(() => {
    flash.success(successMessage ?? "Erfolgreich gespeichert")
}, error => {
    console.error(error)
    const errorData = error?.error?.data
    if(errorData?.code === 400){
        flash.error(errorData?.message ?? "Validierung fehlgeschlagen")
        if(errorData?.errors?.errors?.length > 0){
            flash.error(errorData?.errors?.errors[0])
        }
    }else if (errorData?.code === 500 && errorData?.message){
        flash.error(errorData?.message)
    }else{
        flash.error("Fehler beim Speichern.")
    }
})

export const api = createApi({
    reducerPath: "api",
    baseQuery,
    tagTypes: [
        "accounting/booking",
        "banking-api/transaction",
        "costs/real-costs-origin",
        "costs/margin-report",
        "tourism-journey-calculation/calculation",
    ],
    endpoints: build => ({
        loadEntity: build.query({
            query: ({modelId, id, queryString}) => modelId + "/" + id + (queryString ? "?" + queryString : ""),
            providesTags: (result, error, {modelId, id}) => [modelId, {type: modelId, id}]
        }),

        getCalculations: build.query({
            query: itinerary => ({
                url: "tourism-journey-calculation/calculation",
                params: {
                    conditions: JSON.stringify({itinerary}),
                    "sort[0][0]": "fromDate",
                    "sort[0][1]": "ASC"
                }
            }),
            transformResponse: ({items = []}) => items,
            providesTags: () => ["tourism-journey-calculation/calculation"]
        }),

        calculationDetails: build.query({
            query: body => ({
                url: "tourism-journey-calculation/query/backend",
                method: "POST",
                body
            }),
            keepUnusedDataFor: 0,
            transformResponse(data) {
                const groupKeys = []
                const columnKeys = []
                for (const [groupId, {from, to, columns}] of Object.entries(data?.columns ?? {})) {
                    const keys = Object.keys(columns)
                    groupKeys.push({
                        groupId,
                        from: from.substring(0, 10),
                        to: to.substring(0, 10),
                        colSpan: keys.length
                    })

                    for (const [paxId, [from, to]] of Object.entries(columns)) {
                        columnKeys.push({
                            groupId, paxId, from, to,
                            key: groupId + '|' + paxId,
                            border: paxId === keys[keys.length - 1]
                        })
                    }
                }

                return {...data, groupKeys, columnKeys}
            }
        }),

        calculationSettings: build.query({
            query: id => "organization/organization/" + id,
            transformResponse({settings: {tourismJourneyCalculationSettings: settings}}) {
            }
        }),

        calculationServices: build.query({
            query: () => "form/choice/tourism_journey_calculation_service",
        }),

        calculationSave: build.mutation({
            query: ({id, ...body}) => ({
                url: "tourism-journey-calculation/calculation-edit/" + id,
                method: "PUT",
                body
            }),
            onQueryStarted: onQueryStarted(),
            invalidatesTags: ["tourism-journey-calculation/calculation"]
        }),

        swiftInfo: build.query({
            query: swift => "base/swift-info/" + swift
        }),

        interfaceImplementations: build.query({
            query: interfaceId => ({url: `interface/implementations/${interfaceId}`})
        }),

        contingentPool: build.query({
            query: ({pool, itinerary}) => ({
                url: `tourism-journey/contingent-pool/by-${pool ? "pool" : "itinerary"}/${pool ?? itinerary}`,
                method: "GET"
            }),
            /**
             * @param {ContingentPoolQueryData} result
             */
            transformResponse(result) {
                if (!result?.contingents) {
                    return result
                }

                const {occupancyTypes, contingents, itineraries, pools, accommodations, participants, orders} = result

                const mapLabels = (modelId, displayView, labels) => Object.fromEntries(
                    Object.entries(labels).map(([id, objectLabel]) => [
                        id, {id, modelId, displayView, objectLabel}
                    ])
                )

                const accommodationKeys = Object.keys(accommodations)
                const dates = Object.keys(contingents)
                const rows =
                    // calculate all possible combinations of given types
                    Object.keys(occupancyTypes).flatMap(occupancyType =>
                        Object.keys(pools).flatMap(pool =>
                            Object.keys(itineraries).flatMap(itinerary =>
                                (accommodationKeys.length ? accommodationKeys : [undefined]).map(accommodation => ({
                                    occupancyType,
                                    pool,
                                    itinerary,
                                    accommodation
                                }))
                            )
                        )
                    )
                        // insert day data
                        .map(({occupancyType, pool, itinerary, accommodation}) => ({
                            occupancyType, pool, itinerary, accommodation,
                            ...Object.fromEntries(dates.map(date => {
                                const c = contingents[date].find(c => (
                                    c[0] === pool &&
                                    c[3].includes(itinerary) &&
                                    c[4].includes(occupancyType) &&
                                    (undefined === accommodation || c[5].includes(accommodation))
                                ))

                                return [date, c?.length ? {max: c[1], free: c[2], participants: c[6]} : undefined]
                            }))
                        }))
                        // filter out empty rows
                        .filter(row => dates.some(date => undefined !== row[date]))

                return {
                    dates,
                    rows,
                    occupancyTypes,
                    participants,
                    pools: mapLabels("tourism-journey/contingent-pool", "tourism-journey/contingent-pool-detail", pools),
                    itineraries: mapLabels("tourism/itinerary", "tourism/itinerary", itineraries),
                    accommodations: mapLabels("tourism-accommodation/accommodation", "tourism-accommodation/accommodation", accommodations),
                    orders: mapLabels("order/order", "order/detail", orders),
                }
            }
        }),
    })
})

export const {
    useLoadEntityQuery,
    useGetCalculationsQuery,
    useCalculationDetailsQuery,
    useCalculationServicesQuery,
    useCalculationSaveMutation,
    useInterfaceImplementationsQuery,
    useContingentPoolQuery,
    endpoints: {
        calculationDetails: {
            matchPending: calculationDetailsLoading,
            matchFulfilled: calculationDetailsLoaded
        },
        calculationSave: {
            matchFulfilled: calculationSaved
        },
        swiftInfo,
    }
} = api

export const useOrganizationQuery = id => useLoadEntityQuery({modelId: "organization/organization", id})
